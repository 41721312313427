@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(32,51,84);
  background: linear-gradient(90deg, rgba(5, 7, 11, 0.795) 0%, rgb(0, 0, 0) 100%);
}
.login-bg{
  /* background-image: linear-gradient(180deg, #292929, #000000); */
  /* The image used */
  background-image: url("./images/background.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.glass-bg{
  background: linear-gradient(to right, rgba(255, 255, 255, 0.491), rgba(255, 255, 255, 0.141)) !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.424) !important;
  backdrop-filter: blur(30px) !important;
  -webkit-backdrop-filter: blur(8.7px) !important;
  padding: 1rem;
  padding: 1rem;
}
.images-logo {
  width: 20% !important;
  height: 20% !important;
}

.images-logo2 {
  width: 3% !important;
  height: 3% !important;
}

.btn-login {
  background: rgb(32,51,84,1) !important;
  color: #fff !important;
}

.footer-text {
  color: #fff !important;
}

.lock-icon {
  background: rgba(32,51,84,1) !important;
}

.theme-login {
  background: rgba(32,51,84,1) !important;
}

.background-login {
  background: #f2f2f2 !important;
  padding: 1.5rem;
  border-radius: 10px;
}

.toast-error {
  background: rgb(177, 30, 30) !important;
}

.toast-success {
  background: rgb(15, 158, 15) !important;
}

.btnLogout:hover {
  background: rgb(20, 33, 54) !important;
}